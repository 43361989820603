<div class="flex h-full w-full flex-col text-gray-700">
  <div class="flex justify-between">
    <span class="border-b border-gray-500 font-medium">{{ data.nome }}</span>
    <button mat-icon-button (click)="close()" class="-mt-2">
      <ng-icon name="heroXMark"></ng-icon>
    </button>
  </div>
  <div class="mt-4 grow rounded-md border border-gray-200 p-2">
    <img
      class="h-full w-full rounded-md object-cover"
      *ngIf="imagemPath"
      [src]="imagemPath"
    />
  </div>
</div>
